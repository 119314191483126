import { firstValueFrom } from 'rxjs';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { GridQuestion, GridQuestionTypeEnum } from '../../models/grid-question';
import { DceDocument } from '../../models/dce-document';
import { UserTrackerService } from '../../../shared/services/tracking/user-tracker.service';
import { EventTypeName } from '../../../models/user-tracker';
import { GridAnswer } from '../../models/grid-answer';
import { isEqual } from 'lodash';


@Component({
  selector: 'app-tender-smart-grid',
  templateUrl: './tender-smart-grid.component.html',
  styleUrls: ['./tender-smart-grid.component.scss']
})
export class TenderSmartGridComponent implements OnChanges {

  @Input() tenderId!: string;
  @Input() tenderDceProjectUid!: boolean;
  @Input() dceDocuments: DceDocument[] = [];
  @Input() clickedAnswer: GridAnswer | null = null;
  @Output() clickedAnswerChange = new EventEmitter<GridAnswer | null>();
  @Input() privateQuestions: GridQuestion[] = [];
  @Input() publicQuestions: GridQuestion[] = [];
  @Input() fetchPrivateQuestionsFinished = false;
  @Input() fetchPublicQuestionsFinished = false;
  @Output() editQuestionTrigger = new EventEmitter<GridQuestion>();

  showLoader = true;
  isCopied = false;
  protected isEqual = isEqual;


  constructor(private userTrackerService: UserTrackerService) {
  }


  ngOnChanges(changes: any) {
    if ((this.fetchPublicQuestionsFinished && this.fetchPrivateQuestionsFinished) || !this.tenderDceProjectUid) {
      this.showLoader = false;
    }
  }

  onClickQuestion(question: GridQuestion) {
    if (question.answer) {
      this.isCopied = false;
      this.trackEvent({is_question_selected: this.clickedAnswer !== question.answer, question: question},
        EventTypeName.TENDER_GRID_QUESTION_CLICKED);
      this.clickedAnswerChange.emit(this.clickedAnswer === question.answer ? null : question.answer);
    }
  }

  copyToClipboard(event: any, question: GridQuestion) {
    event.stopPropagation();
    navigator.clipboard.writeText(question.displayedName + ' :\n' + question.answer?.citation);
    this.isCopied = true;
  }

  trackEvent(data = {}, eventName = '') {
    firstValueFrom(this.userTrackerService.track({
      event_type: eventName,
      event_timestamp: (new Date()).toISOString(),
      ...this.userTrackerService.buildBasicUserInformations(),
      tender_id: this.dceDocuments[0].rawKey.split('/')[3],
      ...data
    }));
  }

  trackByQuestionId(index: number, item: GridQuestion) {
    return item.questionId;
  }

    editQuestion(question: GridQuestion) {
        this.editQuestionTrigger.emit(question);
    }

    protected readonly GridQuestionTypeEnum = GridQuestionTypeEnum;
}
