<section
  [hidden]="tenderId"
  id="ms-container"
>
  <!--Header part-->
  <div id="ms-header">
    <div id="tender-search-header">
      <div id="tender-search-title">
        {{ "tenders.search-title" | translate}}
      </div>
      <div class="search-header-container py-2 px-4">
        <app-tenders-filter textSearchEnabled [tenderSearchService]="tenderSearchService">
        </app-tenders-filter>
      </div>
    </div>
    <div *ngIf="tenders?.length" class="result-header-bar d-flex">
      <app-tenders-summary [tendersSearchService]="tenderSearchService" />
      <div class="sort-container ms-auto">
        <app-tenders-sort [tendersSearchService]="tenderSearchService" defaultSort="indexation_date" />
      </div>
    </div>
  </div>

  <app-tenders-list [tendersSearchService]="tenderSearchService"/>
</section>

<router-outlet>
</router-outlet>
