import {
  booleanAttribute,
  Component, EventEmitter,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  signal,
  SimpleChanges,
  WritableSignal
} from '@angular/core';
import { TenderTopic } from '../../models/tender-topic';
import { TreeItem, TreeviewItem } from '../../../lib/ngx-treeview';
import { Period, TendersSearchService } from '../../services/tenders-search.service';
import { buildTenderTerritoriesTreeItems } from '../../services/helpers/tender-territory';
import { DbTender } from '../../models/tender';
import { TerritoryKind } from '../../../core/territory-kind/territory-kind.enum';
import { TranslateService } from '@ngx-translate/core';
import { DbTenderTerritory } from '../../models/tender-territory';
import { TenderTypesEnum } from '../../models/tender-search-body';
import { TendersModuleService } from '../../services/tenders-module.service';

export enum FilterType {
  TOPIC = 'topic',
  TERRITORY = 'territory',
  PERIOD = 'period',
  TYPE = 'type'
}

export enum SearchMode {
  TOPICS = 'topics',
  TEXT = 'text'
}

export enum ForceToggleEnum {
  SEARCH_TYPE = 'search_type',
  TOPICS = 'topics'
}

@Component({
  selector: 'app-tenders-filter',
  templateUrl: './tenders-filter.component.html',
  styleUrls: ['./tenders-filter.component.scss'],
})
export class TendersFilterComponent implements OnInit, OnChanges, OnDestroy {
  protected readonly FilterType = FilterType;
  protected readonly SearchMode = SearchMode;

  @Input({required: true}) tenderSearchService!: TendersSearchService;
  @Input() filters?: FilterType[]
  @Input({transform: booleanAttribute}) textSearchEnabled = false;
  @Input({transform: booleanAttribute}) noLabel = false;
  activeModeSignal: WritableSignal<'topics' | 'text'> = signal('topics');

  tenderTopics?: TenderTopic[];
  defaultTopicIds?: number[];
  defaultTerritoriesIds?: string[];
  defaultTypes?: TenderTypesEnum[];
  territoryItems!: TreeviewItem;
  treeItems: TreeItem[] = new Array<TreeItem>();
  tenders = new Array<DbTender>();
  totalCount = 0;
  territorySelectionCount = 0;
  alternativeDisplay = '';
  userDataLoaded = false;
  searchText: string = '';
  show = false;
  forceToggleMap = new Map<ForceToggleEnum, EventEmitter<boolean>>();

  constructor(private tendersModuleService: TendersModuleService,
              private translate: TranslateService,
  ) {
    this.initForceToggleMap();
  }

  async ngOnInit() {
    this.tenderTopics = await this.tendersModuleService.tenderTopics;
    await this.buildAllRegionsTreeItems();
    this.userDataLoaded = true;
    this.updateFilters();
  }

  initForceToggleMap() {
    this.forceToggleMap.set(ForceToggleEnum.SEARCH_TYPE, new EventEmitter<boolean>());
    this.forceToggleMap.set(ForceToggleEnum.TOPICS, new EventEmitter<boolean>());
  }

  onTextSearch() {
    this.searchText = this.searchText.trim(); // sanitizing spaces at the end of the word
    if (!this.searchText) return;
    this.tenderSearchService.searchText = this.searchText;
  }

  sanitizeText() {
    this.searchText = this.searchText.trim();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tenderSearchService'] && this.userDataLoaded) {
      this.updateFilters();
    }
  }

  async buildAllRegionsTreeItems() {
    // On cherche à récupérer l'ensemble du territoire français.
    // Cette méthode est résiliente aux erreurs de récupération des territoires, et au fait que l'utilisateur n'ait pas tous les départements.
    const territories: DbTenderTerritory[] = await this.tendersModuleService.userRegions;

    // gestion du cas où les territoires ne sont pas récupérés.
    this.alternativeDisplay = territories.length ? '' : 'tenders.error-retrieving-territories';

    this.totalCount = territories.reduce((acc, cur) => acc + (cur.territories?.length ?? 0), 0);
    // construction des éléments de l'arbre des territoires.
    this.treeItems = territories.map((territory) =>
      buildTenderTerritoriesTreeItems(territory)
    );
    // tri des elements
    this.treeItems.sort((a, b) => {
      if (['DROM','COM'].includes(a.text)) return 1;
      return a.text.localeCompare(b.text)
    }); // tri alpha des regions
    this.treeItems.forEach((ti) =>
      ti.children?.sort((a, b) => a.text.localeCompare(b.text)) // tri alpha des départements
    );
    // Création de l'arbre des territoires.
    this.territoryItems = new TreeviewItem({
        checked: false,
        collapsed: false,
        value: null,
        text: this.translate.instant("tenders.territory-filter-all"),
        children: this.treeItems,
        isRoot: true
      }
    );
  }

  topicsSelected(event: number[] | null) {
    this.tenderSearchService.selectedTopics = event;
    this.defaultTopicIds = event ?? undefined;
  }

  periodSelected(event: Period) {
    this.tenderSearchService.selectedPeriod = event;
  }

  territoriesSelected(event: string[]) {
    if (!event.length) {
      event = this.territoryItems.getSelection().uncheckedItems.filter((elm) =>
        !elm.value.includes(TerritoryKind.REGION)).map((item) => item.value);
      this.defaultTerritoriesIds = [];
    } else this.defaultTerritoriesIds = event;
    this.tenderSearchService.selectedTerritories = event;
  }

  typesSelected(event: TenderTypesEnum[]) {
    if (!event.length) {
      event = Object.values(TenderTypesEnum);
      this.defaultTypes = [];
    } else this.defaultTypes = event;
    this.tenderSearchService.tenderTypes = event;
  }

  updateFilters() {
    this.defaultTerritoriesIds = this.tenderSearchService.selectedTerritories?.length === this.totalCount ?
      [] : this.tenderSearchService.selectedTerritories;
    if (!this.tenderSearchService.selectedTerritories) {
      // Selection de tous les territoires par défaut.
      this.territoryItems.children.forEach(child => child.setCheckedRecursive(false));
      this.territorySelectionCount = this.territoryItems.getSelection().uncheckedItems.filter((elm) =>
        !elm.value.includes(TerritoryKind.REGION)).length;
      this.tenderSearchService.selectedTerritories =
        this.territoryItems.getSelection().uncheckedItems.filter((elm) =>
          !elm.value.includes(TerritoryKind.REGION)).map((item) => item.value);
    }
    this.defaultTopicIds = this.tenderSearchService.selectedTopics ?? [];
    this.defaultTypes = this.tenderSearchService.tenderTypes?.length === Object.values(TenderTypesEnum).length ?
      [] : this.tenderSearchService.tenderTypes;
    if (!this.tenderSearchService.tenderTypes) {
      this.tenderSearchService.tenderTypes = Object.values(TenderTypesEnum) as TenderTypesEnum[];
    }
  }

  // not sure of the right behavior here
  updateActiveMode(mode: SearchMode) {
    this.forceToggleMap.get(ForceToggleEnum.SEARCH_TYPE)?.emit(false); //close search_type dropdown.
    if (this.activeModeSignal() === mode) return;
    if (mode === 'topics') {
      this.searchText = '';
      this.tenderSearchService.searchText = '';
      setTimeout(() => this.forceToggleMap.get(ForceToggleEnum.TOPICS)?.emit(true), 50); //open topics dropdown
    } else if (mode === 'text') {
      setTimeout(() => document.getElementById('tenders-search-text-input')?.focus(), 50); //focus search text input
      this.tenderSearchService.selectedTopics = [];
    }
    this.tenderSearchService.activeMode = mode;
    this.activeModeSignal.set(mode);
    this.updateFilters()
  }

  ngOnDestroy() {
    this.tenderSearchService.initActiveMode();
  }

  protected readonly ForceToggleEnum = ForceToggleEnum;
}
