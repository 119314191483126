import { ChunkPageBbox, DbChunkPageBbox } from './chunk-page-bbox';


export interface DbChunkDetail {
  chunk_id: string;
  chunk_pages_bboxes: DbChunkPageBbox[];
}

export class ChunkDetail {
  chunkId: string;
  chunkPagesBboxes: ChunkPageBbox[];

  constructor(dbChunkDetail: DbChunkDetail) {
    this.chunkId = dbChunkDetail.chunk_id;
    this.chunkPagesBboxes = dbChunkDetail.chunk_pages_bboxes.map((pageBbox) => new ChunkPageBbox(pageBbox));
  }
}
