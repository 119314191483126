import { Component, OnInit } from '@angular/core';
import { TendersModuleService } from '../../services/tenders-module.service';
import { StatusItem } from '../../models/tender-status';

@Component({
  selector: 'app-tenders',
  templateUrl: './tenders.component.html',
  styleUrls: ['./tenders.component.scss'],
  providers: [TendersModuleService]
})
export class TendersComponent implements OnInit {
  colors: {[p:string]: string} = {
    opportunite: '#FC4C02',
    a_creuser: '#FFAA00',
    pour_info: '#0095FF',
    pas_interessant: '#6D82AA',
    go: '#00B383',
    no_go: '#F93E4F',
    gagne: '#6816CC',
    perdu: '#000000',
  };

  constructor(private tendersModuleService: TendersModuleService) { }

  async ngOnInit() {
    await this.tendersModuleService.groupAccountStatuses
      .then((res) => {
        this.tendersModuleService.statusItems = res.data.map((elm) => {
          return {
            label: elm.displayed_status_name,
            value: new StatusItem({...elm, color: this.colors[elm.status_name]})
          };
        });
      }
    );
  }

}
