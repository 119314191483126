<div id="tenders-summary" [ngClass]="{'loading': tendersSearchService.inSearch && !tendersSearchService.offset}">
  <div class="results-count">
    <ng-container *ngIf="usePaginationCount">
      {{
        'tenders.x-market' +
        ((tendersSearchService.tenders?.length ?? 0) > 1 ? 's' : '') |
          translate: {count: tendersSearchService.tenders?.length ?? 0 }
      }}
    </ng-container>
    <ng-container *ngIf="!usePaginationCount">
      {{
        'tenders.x-market' +
        (tendersSearchService.totalCount > 1 ? 's' : '') |
        translate: {count: tendersSearchService.totalCount}
      }}
    </ng-container>
  </div>
  <div *ngIf="!noPeriodDisplayed" class="results-period">
    <ng-container *ngIf="usePaginationCount">
      {{
        'tenders.on-x-market' +
        (tendersSearchService.totalCount > 1 ? 's-retained' : '-retained') |
          translate: {count: tendersSearchService.totalCount}
      }}
    </ng-container>
    <ng-container *ngIf="!usePaginationCount">
      {{
        (withTime ? 'tenders.datetime-info' : (toToday() ? 'tenders.date-info-today' : 'tenders.date-info')) |
          translate: getDateTextInfo()
      }}
    </ng-container>
  </div>
</div>
