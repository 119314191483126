<ex-badge icon="timer-filled"
          [text]="(days < 0 ?
            'tenders.badge.over' :
                (days === 0 ?
                    'tenders.badge.today' :
                    'tenders.badge.day' + (days > 1 ? 's' : '')
                )
            ) | translate : {count: days}"
          [backgroundColor]="colorMap[color].backgroundColor"
          [color]="colorMap[color].color"
          borderColor="10%"
/>
