<div *ngIf="tenderDetail" class="tender-detail-container d-flex justify-content-evenly flex-nowrap">
  <div class="left-part d-flex flex-column">
    <div class="tender-detail-info-header d-flex flex-column">
      <div class="title limited-height"
           [ngbTooltip]="tenderDetail.title"
           placement="bottom auto"
           overflowTooltip
           tooltipClass="tender-title-tooltip"
           [openDelay]="500"
      >{{tenderDetail.title }}</div>

      <div class="tender-qualification-line d-flex pdb-8px">
        <app-tender-status
          [tender]="tenderDetail"
          size="small">
        </app-tender-status>
        <app-tender-share
          *ngIf="!tenderDetail.hide"
          [tender]="tenderDetail"
          size="small"
          displayShareText>
        </app-tender-share>
      </div>
      
      <div class="d-flex justify-content-between align-items-center">

        <!--Partie gauche contenant les badges -->
        <div class="badges-container">
          <app-remaining-days-badge *ngIf="tenderDetail.daysLeft"
                                    [days]="tenderDetail.daysLeft"
                                    color="gray"
          />
          <app-tender-type-badge *ngIf="tenderDetail.tenderType !== 'undetermined'"
                                 [tenderType]="tenderDetail.tenderType"
          />
          <ng-container *ngFor="let marketType of tenderDetail.marketType">
            <ex-badge backgroundColor="var(--basic-250)"
                      color="var(--basic-600)"
                      borderColor="10%"
                      icon="briefcase-filled"
                      text="{{marketType | articleTitleCase}}"
                      class="ms-2"
            />
          </ng-container>
        </div>
      </div>
    </div>

    <div (scrollend)="onScrollEnd()" class="tender-detail-info-body">

      <!--section metadata-->
      <app-tender-metadata [tenderDetail]="tenderDetail"></app-tender-metadata>

      <!--section NOTE-->
      <div class="note-editor-container">
        <ex-note-editor
          [dbNote]="tenderDetail.note ?? ''"
          [service]="apiTenderService.annotation"
          [entityId]="tenderDetail.id"
          [annotationId]="dbTenderAnnotation.id"
          context="in-detail-view"
          (noteChange)="noteChange(tenderDetail, $event)"
        ></ex-note-editor>
      </div>

      <!--titre de section smartgrid-->
      <div class="d-flex justify-content-between px-3">
        <div class="section-title d-flex align-items-center">
          {{'tenders.section-title.smart-grid' | translate | uppercase}}
          <!--Explain AI cartouche-->
          <ex-badge backgroundColor="var(--color-semantic-ai-bg-light)"
                    color="var(--color-semantic-ai-on-bg-light)"
                    borderColor="100%"
                    icon="sparks-filled"
                    text="{{'tenders.explain-ai' | translate}}"
                    class="ms-2"
          />
        </div>
        <button class="add-question-button"
                *ngIf="!isQuestionFormOpened"
                (click)="openAddPrivateQuestionForm()"
                [disabled]="grid[GridQuestionTypeEnum.PRIVATE].questions.length >= 5"
                [ngbTooltip]="grid[GridQuestionTypeEnum.PRIVATE].questions.length >= 5
                ? ('tenders.smart-grid.max-questions' | translate) :'' "
                tooltipClass="simple-tooltip"
                placement="bottom auto" [openDelay]="500"
        >
          <mat-icon svgIcon="plus"></mat-icon>
          <span>{{'tenders.tender-detail.add' | translate}}</span>
        </button>
      </div>
      <app-tender-grid-question-form
        [(isVisible)]="isQuestionFormOpened"
        [action]="questionFormAction"
        [questionToEdit]="questionToEdit"
        (questionAdded)="handleQuestionAdded()"
      ></app-tender-grid-question-form>
      <div class="smart-grid-public-private">
        <app-tender-smart-grid
          *ngIf="!isQuestionFormOpened"
          [tenderId]="tenderId"
          [tenderDceProjectUid]="!!tenderDetail.dceProjectUid"
          [dceDocuments]="tenderDetail?.dceDocuments ?? []"
          [(clickedAnswer)]="clickedAnswer"
          [publicQuestions]="grid[GridQuestionTypeEnum.PUBLIC].questions"
          [privateQuestions]="grid[GridQuestionTypeEnum.PRIVATE].questions"
          [fetchPublicQuestionsFinished]="grid[GridQuestionTypeEnum.PUBLIC].ready"
          [fetchPrivateQuestionsFinished]="grid[GridQuestionTypeEnum.PRIVATE].ready"
          (editQuestionTrigger)="openEditPrivateQuestionForm($event)"
        ></app-tender-smart-grid>
      </div>
    </div>
  </div>
  <div class="right-part w-100">
    <app-tender-pdfs-reader
      [tenderDetail]="tenderDetail"
      [(clickedAnswer)]="clickedAnswer"
    ></app-tender-pdfs-reader>
  </div>
</div>
