import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-control-f',
  templateUrl: './control-f.component.html',
  styleUrls: ['./control-f.component.scss']
})
export class ControlFComponent implements OnChanges {
  private _searchText = '';
  private _textChanged = false;
  public currentMatchNumber!: number;
  @Input() display = 'normal';
  @Input() styleMode: 'light' | 'medium' = 'medium';
  @Input() totalMatches!: number;
  @Input() documentLoaded!: boolean;
  @Input() displayButton = false;
  // tslint:disable:no-output-on-prefix
  @Output() onSearch = new EventEmitter<string>();
  @Output() onNext = new EventEmitter<number>();
  @Output() onPrev = new EventEmitter<number>();
  // tslint:enable:no-output-on-prefix
  @Input() inputValue!: string;
  @Input() placeholderForTranslate = 'search.search-placeholder-not-quote-mark';
  @Input() inputBackgroundColor : 'white' | 'grey' = 'white';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['inputValue'] && (this.inputValue !== this.searchText)) {
      this.searchText = this.inputValue;
    }
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && (event.code === 'KeyF' || event.code === 'Keyf')) {
      event.preventDefault();
      document.getElementById('search-test')?.focus();
    }
  }

  get searchText(): string {
    return this._searchText;
  }

  set searchText(text: string) {
    this._textChanged = text !== this._searchText;
    this._searchText = text;
    if (!this._searchText) {
      this.find();
      this.totalMatches = 0;
      this.currentMatchNumber = 0;
    }
  }

  find(): void {
    if (this._textChanged) {
      this._textChanged = false;
      this.currentMatchNumber = 1;
      this.onSearch.emit(this.searchText);
    } else {
      this.findNext();
    }
  }

  findNext(): void {
    if (!this.totalMatches) {
      return;
    }
    this.currentMatchNumber++;
    if (this.currentMatchNumber > this.totalMatches) {
      this.currentMatchNumber = 1;
    }
    this.onNext.emit(this.currentMatchNumber);
  }

  findPrevious(): void {
    if (!this.totalMatches || this.totalMatches < 2) {
      return;
    }
    this.currentMatchNumber--;
    if (this.currentMatchNumber < 1) {
      this.currentMatchNumber = this.totalMatches;
    }
    this.onPrev.emit(this.currentMatchNumber);
  }

}
