<ex-dropdown label="{{(useShortLabel ? 'common.by' : 'common.sort-by') | translate}}"
             noInput
             size="small"
             [inputText]="activeItem && (activeItem.label | translate)"
             blackStyle
>
    <ng-template exDropdownContent>
        <div class="sort-items-container">
            <button *ngFor="let item of items" class="sort-item" [ngClass]="{'active': item === activeItem}"
                    (click)="onSort(item)" [disabled]="item === activeItem && item.unidirectional">
                <div class="d-flex flex-column align-items-start flex-1">
                    <div class="sort-name">{{item.label | translate}}</div>
                    <div *ngIf="item === activeItem" class="sort-description">{{item.description && (item.description | translate)}}</div>
                </div>
                <div class="sort-icon" [hidden]="item !== activeItem || (item === activeItem && item.unidirectional)">
                    <img [hidden]="item.direction === 'asc'" src="/assets/images/icon-arrow-down-outline.svg" alt="desc-sort">
                    <img [hidden]="item.direction === 'desc'" src="/assets/images/icon-arrow-up-outline.svg" alt="asc-sort">
                </div>
            </button>
        </div>
    </ng-template>
</ex-dropdown>
