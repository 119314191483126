import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HeadersService } from '../headers/headers.service';
import { User } from '../../../../models/user';

interface DbUserRetrieveUserInfo {
  'email': string;
  'group_account_id': number;
  'id': number;
}

export class UserRetrieveUserInfo {
  email: string;
  groupAccountId: number;
  id: number;

  constructor(dbUserRetrieveUserInfo: DbUserRetrieveUserInfo) {
    this.email = dbUserRetrieveUserInfo.email;
    this.groupAccountId = dbUserRetrieveUserInfo.group_account_id;
    this.id = dbUserRetrieveUserInfo.id;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiUserService {

  constructor(private http: HttpClient,
              private headersService: HeadersService) { }

  searchUsers(filters: any): Observable<Array<User>> {
    const body = {
      'fields': [
        'id',
        'first_name',
        'last_name',
        'email',
        'user_level',
        'group_account_id',
        'is_active',
        'created_at',
        'updated_at',
        'modules'
      ],
      'filters': filters,
      'limit': 1000
    };
    return this.http.post(`${environment.explainApiUrl}user`, body, this.headersService.httpHeaders)
      .pipe(map((response: any) => {
        return response['data'].map((item: any) => {
          item.kind = 'DbUserPost';
          return new User(item);
        });
      }));
  }

  getOwnUser(): Observable<User> {
    const userId = localStorage.getItem('user_id');
    return this.http.get(`${environment.explainApiUrl}user/${userId}`, this.headersService.httpHeaders)
      .pipe(map((dbUserGet: any) => {
        dbUserGet.kind = 'DbUserGet';
        return new User(dbUserGet);
      }));
  }
}
