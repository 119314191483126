
export interface DbStatusItem {
  id: number;
  status_name: string;
  displayed_status_name: string;
  color?: string;
  count?: number;
}

export class StatusItem {
  id: number;
  name?: string;
  displayName?: string;
  color?: string;
  count?: number;

  constructor(dbStatus: DbStatusItem) {
    this.id = dbStatus.id;
    this.name = dbStatus.status_name;
    this.displayName = dbStatus.displayed_status_name;
    this.color = dbStatus.color;
    this.count = dbStatus.count;
  }
}

export interface DbCountStatusItem {
  status_id?: number;
  count?: number;
}
