<ex-dropdown *ngIf="context === 'in-list-view'; else wideNoteEditor"
   #narrowNoteEditor id="narrowNoteEditor"
  class="ls-m1"
  size="x-small"
  noInput
  noChevron
  ignoreEnterKey
  [forceToggle$]="forceToggle$"
  (dropdownToggle)="onDropdownToggle($event)"
  (outsideClicked)="clickOutside($event)"
  target="none"
>

  <ng-template exDropdownDisplay>

    <div class="d-flex w-100 align-items-center justify-content-evenly">
      <i class="{{dbNote ? 'ph-fill' : 'ph'}} ph-notepad"></i>
      <span class="note-placeholder" *ngIf="displayText && !isPanelOpen">{{'tenders.note.note-placeholder' | translate}}</span>
    </div>

  </ng-template>

  <ng-template exDropdownContent>
    <div class="d-flex flex-column note-editor">
      <textarea #narrowNoteTextArea id="narrowNoteTextArea"
        [(ngModel)]="note" placeholder="Ecrivez votre note ici..."
                class="border-radius-8px"
                cols="33"
                cdkTextareaAutosize
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"
                maxlength="300"
                (click)="mode = 'edition'"
      ></textarea>
      <div class="footer-part overflow-hidden flex-center-end">
        <ng-container *ngTemplateOutlet="editionButton; context: {template: narrowNoteTextArea}"/>
      </div>
    </div>
  </ng-template>

</ex-dropdown>


<ng-template #wideNoteEditor id="wideNoteEditor">
  <div class="note-section-container" [style.min-height.px]="note ? 80 : 0">
    <div class="note-container">
      <div class="title flex-center-between">
        <div class="flex-center-start">
          <i class="ph-fill ph-notepad"></i>
          <span class="fw-700">{{ 'tenders.note.note' | translate | uppercase }}</span>
        </div>
        <div class="btn-action flex-center-end">

          <div *ngIf="!note && mode !== 'edition'" class="flex-center-end bdr-4px">
            <button class="add-note-btn" (click)="mode='edition'; wideNoteTextArea.focus()">
              <div class="flex-center-center">
                <i class="ph ph-plus"></i>
                <span class="ls-m1 fw-600 pdl-4px">{{'tenders.note.add-note' | translate }}</span>
              </div>
            </button>
          </div>
          <div *ngIf="note || mode === 'edition'">
            <ng-container *ngTemplateOutlet="editionButton; context: {template: wideNoteTextArea}"/>
          </div>
        </div>
      </div>
      <div class="note-content">
          <textarea #wideNoteTextArea id="wideNoteTextArea"
                    (blur)="$event.stopPropagation(); updateNote()"
                    placeholder="Ecrivez votre note ici..."
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="5"
                    maxlength="300"
                    (click)="mode = 'edition'"
                    [(ngModel)]="note">
          </textarea>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editionButton let-template=template>
  <div class="btn-container flex-center-end">
    <button class="textarea-button outline-and-rounded"
            (click)="$event.stopPropagation(); mode === 'edition' ? updateNote() : template.focus(); mode = 'edition'">
      <div class="flex-center-center">
        <span *ngIf="mode === 'edition'">{{ 'tenders.note.save' | translate }}</span>
        <span *ngIf="mode === 'view'">{{ 'tenders.note.edit' | translate }}</span>
      </div>
    </button>
  </div>
</ng-template>
