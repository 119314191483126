import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';
import { IconModule } from '../shared/icon/icon.module';
import {
  ExDropdownComponent,
  ExDropdownContentDirective,
  ExDropdownFooterDirective,
  ExDropdownItemDirective,
  ExDropdownDisplayDirective,
} from './components/ex-dropdown/ex-dropdown.component';
import { FormsModule } from '@angular/forms';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { PreventDoubleClickDirective } from './directives/prevent-double-click.directive';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SimpleLoaderComponent } from './components/simple-loader/simple-loader.component';
import { EmphasizeFilterPipe } from './pipes/emphasize-filter.pipe';
import { PaginationDumbComponent } from './components/pagination-dumb/pagination-dumb.component';
import { ExSortedTableHeaderComponent } from './components/ex-sorted-table-header/ex-sorted-table-header.component';
import { OverflowTooltipDirective } from './directives/overflow-tooltip.directive';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { PdfReaderMenusComponent } from "./components/pdf-reader-menus/pdf-reader-menus.component";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { ControlFComponent } from "../components/control-f/control-f.component";
import { ExIconAndTextComponent } from './components/ex-icon-and-text/ex-icon-and-text.component';
import { ExSortDropdownComponent } from './components/ex-sort-dropdown/ex-sort-dropdown.component';
import { ExTreeNodeComponent } from './components/ex-tree-node/ex-tree-node.component';
import { ExBadgeComponent } from './components/ex-badge/ex-badge.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ToastErrorComponent } from "./components/toast-error/toast-error.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { ArticleTitleCasePipe } from "../shared/pipes/article-title-case.pipe";
import { ArticleTitleListCasePipe } from "../shared/pipes/article-title-list-case.pipe";
import { SortPipe } from "../shared/pipes/sort.pipe";
import { ExMenuComponent } from './components/ex-menu/ex-menu.component';
import { ExMenuItemComponent } from './components/ex-menu-item/ex-menu-item.component';
import { ExModuleHomeComponent } from './components/ex-module-home/ex-module-home.component';
import { TenderActionButtonComponent } from './components/tender-action-button/tender-action-button.component';
import {
  ExEmptyStateComponent,
  ExEmptyStateContentDirective
} from './components/ex-empty-state/ex-empty-state.component';
import { ExOverlayLoaderComponent } from './components/ex-overlay-loader/ex-overlay-loader.component';
import { ExWatchUsersSelectorComponent } from './components/ex-watch-users-selector/ex-watch-users-selector.component';
import { FilterPipe } from "../shared/pipes/filter.pipe";
import {
  DigestWeekDaysSelectorComponent
} from "./components/digest-week-days-selector/digest-week-days-selector.component";
import { ExNoteEditorComponent } from './components/ex-note-editor/ex-note-editor.component';
import { MatInputModule } from "@angular/material/input";
import { ExChipComponent } from './components/ex-chip/ex-chip.component';


@NgModule({
  declarations: [
    // ** components **
    ExDropdownComponent,
    SimpleLoaderComponent,
    BreadcrumbComponent,
    // ** pipes **
    EmphasizeFilterPipe,
    TruncatePipe,
    ArticleTitleCasePipe,
    ArticleTitleListCasePipe,
    SortPipe,
    // ** directives **
    ClickOutsideDirective,
    PreventDoubleClickDirective,
    ExDropdownContentDirective,
    ExDropdownItemDirective,
    ExDropdownFooterDirective,
    ExDropdownDisplayDirective,
    OverflowTooltipDirective,
    ExEmptyStateContentDirective,

    PaginationDumbComponent,
    ExSortedTableHeaderComponent,
    PdfReaderMenusComponent,
    ControlFComponent,
    ExIconAndTextComponent,
    ExSortDropdownComponent,
    ExTreeNodeComponent,
    ExBadgeComponent,
    ToastErrorComponent,
    LoaderComponent,
    ExMenuComponent,
    ExMenuItemComponent,
    ExModuleHomeComponent,
    TenderActionButtonComponent,
    ExEmptyStateComponent,
    ExOverlayLoaderComponent,
    ExWatchUsersSelectorComponent,
    FilterPipe,
    DigestWeekDaysSelectorComponent,
    ExNoteEditorComponent,
    ExChipComponent

  ],
  exports: [
    ExDropdownComponent,
    ClickOutsideDirective,
    PreventDoubleClickDirective,
    ExDropdownContentDirective,
    ExDropdownItemDirective,
    ExDropdownFooterDirective,
    ExDropdownDisplayDirective,
    ExEmptyStateContentDirective,
    TranslateModule,
    SimpleLoaderComponent,
    BreadcrumbComponent,
    TruncatePipe,
    EmphasizeFilterPipe,
    ArticleTitleCasePipe,
    ArticleTitleListCasePipe,
    PaginationDumbComponent,
    ExSortedTableHeaderComponent,
    OverflowTooltipDirective,
    PdfReaderMenusComponent,
    ControlFComponent,
    ExIconAndTextComponent,
    ExSortDropdownComponent,
    ExBadgeComponent,
    ExTreeNodeComponent,
    ToastErrorComponent,
    LoaderComponent,
    SortPipe,
    FilterPipe,
    ExMenuComponent,
    TenderActionButtonComponent,
    ExEmptyStateComponent,
    ExOverlayLoaderComponent,
    ExWatchUsersSelectorComponent,
    DigestWeekDaysSelectorComponent,
    ExNoteEditorComponent,
    ExChipComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    MaterialModule,
    IconModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    NgxExtendedPdfViewerModule,
    NgbTooltip,
    MatInputModule,
  ]
})
export class CommonExplainModule { }

registerLocaleData(localeFr, 'fr');
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json?cb=' + new Date().getTime());
}
