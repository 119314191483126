<div [hidden]="tenderEntityService.tenderId$ | async" id="mw-container">
  <div id="mw-header">
    <div id="market-watches">
      <div *ngFor="let manager of marketWatchManagers"
           class="market-watch-tab"
           [ngClass]="{'active-tab': currentManager == manager}"
           (click)="tendersModuleService.setCurrentManager(manager)" (keydown)="tendersModuleService.setCurrentManager(manager)"
           ngbTooltip="{{manager.marketWatch?.name}}"
           [placement]="['bottom', 'auto']"
           container="body"
           overflowTooltip
           [watchTarget]="tabName"
           tooltipClass="mw-tooltip"
           [openDelay]="1000"
      >
        <span #tabName>{{ manager.marketWatch?.name ?? ('tenders.market-watches.new-watch' | translate) }}</span>
        <div *ngIf="!manager.marketWatch && marketWatchManagers.length > 1"
             class="close-icon d-flex align-items-center justify-content-center pdl-8px"
             (click)="closeNewMarketWatch(manager)" (keydown)="closeNewMarketWatch(manager)">
          <mat-icon svgIcon="x" [ngbTooltip]="'tenders.market-watches.cancel-creation' | translate" container="body" [placement]="['bottom', 'auto']"/>
        </div>
      </div>
      <button
        id="new-watch-button"
        (click)="newMarketWatch()"
        [disabled]="!(marketWatchManagers.length < MARKET_WATCHES_LIMIT) || hasNewMarketWatch()"
        [hidden]="marketWatchManagers.length == 1 && !currentManager.marketWatch"
      >
        <mat-icon svgIcon="plus"/>
      </button>
    </div>
    <div id="mw-header-container">

      <!--Existing market watch name-->
      <div *ngIf="currentManager.marketWatch" id="mw-name-container" class="header-section">

        <!-- Name of mw - seen by owner -->
        <div *ngIf="currentManager.marketWatch?.isOwnedByCurrentUser"
             class="name-edit-box"
             [ngClass]="{
            'invalid': isInvalidName(nameInput.innerText),
            'editing': newName != undefined,
            'show-buttons': currentManager.editMode
          }"
             appClickOutside
             (clickOutside)="resetName()"
             (click)="nameInput.focus()" (keydown)="nameInput.focus()"
        >
          <div
              #nameInput
              id="market-watch-name"
              contenteditable
              (focusin)="newName = currentManager.marketWatch.name"
              (keyup)="newName = nameInput.innerText"
              (keydown.enter)="$event.preventDefault()"
              (keyup.enter)="renameMarketWatch()"
              [innerText]="currentManager.marketWatch.name"
          >
          </div>
          <button [hidden]="newName != undefined" class="rename-btn ex-btn ex-btn-optional sm" (click)="nameInput.focus()"
                  [ngbTooltip]="'tenders.market-watches.rename' | translate" [placement]="['bottom', 'auto']"
                  tooltipClass="mw-tooltip" [openDelay]="500"
          >
            <mat-icon svgIcon="pencil-filled"/>
          </button>
          <button [hidden]="newName == undefined" class="ex-btn ex-btn-success sm" (click)="renameMarketWatch()"
                  [disabled]="newName?.trim() === currentManager.marketWatch.name || isInvalidName()"
                  [ngbTooltip]="'tenders.market-watches.save-name' | translate" [placement]="['bottom', 'auto']"
                  [disableTooltip]="newName?.trim() === currentManager.marketWatch.name || isInvalidName()"
                  tooltipClass="mw-tooltip" [openDelay]="500"
          >
            <mat-icon svgIcon="floppy-disk-filled"/>
          </button>
        </div>

        <!-- Name of mw - seen by subscriber -->
        <div *ngIf="!currentManager.marketWatch?.isOwnedByCurrentUser"
             class="name-edit-box-not-owner">
          <div class="market-watch-name"
               [innerText]="currentManager.marketWatch.name">
          </div>
        </div>

        <div *ngIf="isExistingName(newName)" class="name-exists-error">
          {{ 'tenders.market-watches.name-exists-error' | translate }}
        </div>
        <button [hidden]="!currentManager.editMode || currentManager.newMarketWatchName != undefined" class="ex-btn ex-btn-danger sm" (click)="deleteMarketWatch()"
                [ngbTooltip]="'tenders.market-watches.delete' | translate" [placement]="['bottom', 'auto']"
                tooltipClass="mw-tooltip" [openDelay]="500"
        >
          <mat-icon svgIcon="trashcan-filled"/>
          <span>{{ 'tenders.market-watches.delete' | translate | uppercase }}</span>
        </button>
        <div class="d-flex flex-grow-1"></div>

        <!--add new userwatch button-->
        <button *ngIf="currentManager.marketWatch.isOwnedByCurrentUser && !currentManager.editMode && (currentManager.marketWatch.watchUsers?.length ?? 0) <= 0"
                (click)="manageWatchUsers()"
                class="d-flex ex-btn ex-btn-info"
                [ngbTooltip]="'tenders.market-watches.add-user' | translate" [placement]="['bottom', 'auto']" container="body"
                tooltipClass="mw-tooltip" [openDelay]="500">
          <mat-icon svgIcon="people-plus-fill"/>
          <span>
            {{ 'tenders.watch-users.add-watch-users' | translate | uppercase }}
          </span>
        </button>

        <!--manage watch users button-->
        <button *ngIf="(currentManager.marketWatch.isOwnedByCurrentUser && !currentManager.editMode && (currentManager.marketWatch.watchUsers?.length ?? 0) > 0) "
                (click)="manageWatchUsers()"
                class="d-flex ex-btn ex-btn-info"
                [ngbTooltip]="tooltipTemplate" container="body" [placement]="['bottom', 'auto']"
                tooltipClass="user-watch-tooltip" [openDelay]="500"

        >
          <mat-icon svgIcon="peoples-fill"/>
          <span>
            {{ (currentManager.marketWatch.watchUsers?.length ?? 0) }}
          </span>
        </button>

        <ng-template #tooltipTemplate>
          <div>
            <span>{{ 'tenders.watch-users.tooltip.' + watchUsersTooltip.pre_text_key | translate }}</span>
            <span>{{ watchUsersTooltip.text }}</span>
            <span>{{ 'tenders.watch-users.tooltip.' + watchUsersTooltip.post_text_key | translate : {count: watchUsersTooltip.sub_count_minus_5 > 1 ? watchUsersTooltip.sub_count_minus_5 : undefined} }}</span>
          </div>
          <div *ngIf="currentManager.marketWatch.isOwnedByCurrentUser" class="fst-italic">{{ 'tenders.watch-users.tooltip.info' | translate }}</div>
        </ng-template>

        <!--see watch users button if not owner-->
        <button *ngIf="!currentManager.marketWatch.isOwnedByCurrentUser && !currentManager.editMode && (currentManager.marketWatch.watchUsers?.length ?? 0) > 0"
                class="d-flex ex-btn ex-btn-info-transparent-bg"
                [ngbTooltip]="tooltipTemplate" container="body" [placement]="['bottom', 'auto']"
                tooltipClass="user-watch-tooltip" [openDelay]="500"
        >
          <mat-icon svgIcon="peoples-fill"/>
          <span>
            {{ (currentManager.marketWatch.watchUsers?.length ?? 0) }}
          </span>
        </button>

        <!-- frequency selection if owner-->
        <button *ngIf="!currentManager.editMode && currentManager.marketWatch.isOwnedByCurrentUser"
                (click)="openFrequencyModal()"
                class="d-flex ex-btn ex-btn-basic text-uppercase">
          <mat-icon svgIcon="icon-email-fill"/>
        </button>

        <!--parameters if owner-->
        <button *ngIf="currentManager.marketWatch && !currentManager.editMode && currentManager.marketWatch.isOwnedByCurrentUser" (click)="toggleEditMode()"
                class="d-flex ex-btn ex-btn-basic text-uppercase"
                [ngbTooltip]="'tenders.market-watches.settings' | translate" [placement]="['bottom', 'auto']"
                tooltipClass="mw-tooltip" [openDelay]="500">
          <mat-icon svgIcon="parameters-filled"/>
          <span>
            {{ 'tenders.market-watches.parameters' | translate }}
          </span>
        </button>

        <!--unsubscribe if not owner-->
        <button *ngIf="currentManager.marketWatch && !currentManager.editMode && !currentManager.marketWatch.isOwnedByCurrentUser"
                (click)="unsubscribe()"
                class="d-flex ex-btn ex-btn-danger"
                [ngbTooltip]="'tenders.market-watches.unsubscribe' | translate" [placement]="['bottom', 'auto']"
                tooltipClass="mw-tooltip" [openDelay]="500">
          <mat-icon svgIcon="people-minus-fill"/>
          <span>
            {{ 'tenders.watch-users.unsubscribe' | translate | uppercase }}
          </span>
        </button>

        <button
          *ngIf="currentManager.marketWatch && currentManager.editMode"
          (click)="revertChanges()"
          class="ex-btn">
          {{ 'tenders.market-watches.cancel' | translate}}
        </button>
        <button
          *ngIf="currentManager.marketWatch && currentManager.editMode"
          (click)="saveMarketWatch()"
          class="ex-btn ex-btn-success text-uppercase"
          [disabled]="!hasChanges() || !currentManager.service.watchFilters"
          [ngbTooltip]="'tenders.market-watches.save-mw' | translate" [placement]="['bottom', 'auto']" container="body"
          tooltipClass="mw-tooltip" [openDelay]="500">
          <mat-icon svgIcon="floppy-disk-filled"/>
          <span>
                {{ 'tenders.market-watches.save' | translate}}
              </span>
        </button>
      </div>

      <!--On new market watch creation-->
      <div *ngIf="currentManager.newMarketWatchName !== undefined" id="new-mw-name-container" class="header-section">
        <div
          class="name-edit-box d-flex"
          [ngClass]="{
            'invalid': isInvalidName(newMarketWatchName),
            'editing': newMarketWatchName != undefined,
            'show-buttons': true
          }"
          appClickOutside
          (clickOutside)="resetNewMarketWatchName()"
          (click)="newMWNameInput.focus()" (keydown)="newMWNameInput.focus()"
        >
          <div
              #newMWNameInput
              id="new-market-watch-name"
              class="flex-grow-1 single-line-text"
              [ngClass]="{'untouched': !newMarketWatchName}"
              contenteditable
              (focusin)="newMarketWatchName = currentManager.newMarketWatchName"
              (keyup)="newMarketWatchName = newMWNameInput.innerText"
              (keydown.enter)="$event.preventDefault()"
              (keyup.enter)="currentManager.newMarketWatchName = newMWNameInput.innerText"
              [innerText]="currentManager.newMarketWatchName"
          >
          </div>
          <div class="flex-grow-2"></div>
          <div class="counter-text" *ngIf="newMarketWatchName">{{ newMarketWatchName?.length }}/{{ MARKET_WATCHES_NAME_LIMIT }}</div>

        </div>
        <div *ngIf="isExistingName(newMarketWatchName)" class="name-exists-error">
          {{ 'tenders.market-watches.name-exists-error' | translate }}
        </div>
        <div class="d-flex flex-grow-1"></div>
        <div class="actions-container d-flex">

          <!--save button - enregistrer la nouvelle veille -->
          <button
            *ngIf="currentManager.creationMode"
            (click)="saveMarketWatch()"
            class="ex-btn ex-btn-success text-uppercase"
            [disabled]="!currentManager.service.watchFilters || isInvalidName(newMWNameInput.innerText)"
          >
            <mat-icon svgIcon="floppy-disk-filled"/>
            <span>
                {{ 'tenders.market-watches.save-new' | translate}}
              </span>
          </button>
        </div>
      </div>

      <div [ngClass]="{'d-flex flex-column flex-column-reverse': !currentManager.marketWatch}">
        <div [hidden]="!currentManager.marketWatch && !currentManager.service.tenders" id="mw-info" class="header-section">
          <div class="d-flex gap-3">
            <app-day-swiper
              [defaultDate]="getDate()"
              [marketWatchDates]="currentManager.marketWatch?.marketWatchDates ?? []"
              (daySelect)="onDaySelect($event)"
              [editMode]="currentManager.editMode"
              [lowerBoundaryDate]="currentManager.marketWatch?.createdAt"
            />
            <app-tenders-summary [tendersSearchService]="currentManager.service" withTime />
          </div>
          <div id="mw-actions">
            <app-tenders-sort
              *ngIf="!currentManager.editMode || (!currentManager.marketWatch && currentManager.service.tenders)"
              [tendersSearchService]="currentManager.service"
              [sortFields]="[SortFieldEnum.RELEVANCE, SortFieldEnum.CLOSING_DATE]"
              [defaultSort]="'_score'"
              [size]="'unset'"
            />
          </div>
        </div>
        <div
          *ngIf="currentManager.marketWatch?.isOwnedByCurrentUser || currentManager.creationMode"
          [hidden]="!currentManager.editMode" class="filters-container header-section"
        >
          <app-tenders-filter
            [tenderSearchService]="currentManager.service"
            [filters]="[FilterType.TOPIC, FilterType.TERRITORY, FilterType.TYPE]"
          />

        </div>
      </div>
    </div>
  </div>
  <tr class="new-params" *ngIf="currentManager.service.tenders && !currentManager.service.inSearch && newParamsNotActiveYet() && tendersModuleService.currentManager.marketWatch?.isOwnedByCurrentUser && !currentManager.service.tenders?.length">
    <td class="new-params-td" colspan="6">
      <div class="d-flex align-items-center justify-content-center">
        <i class="ph ph-clock-clockwise"></i>
        <span>{{ 'tenders.market-watches.new-params-will-be-applied' | translate }}
          {{ 'alert.days.label.' + calculateNextWatchDay() | translate }}
          {{ 'tenders.market-watches.next' | translate }}
        </span>
      </div>
    </td>
  </tr>
  <app-tenders-list class="position-relative"
                    [tendersSearchService]="currentManager.service"
                    [inWatches]="true"
                    [isFirstWatch]="marketWatchManagers.length === 1 && !currentManager.marketWatch"
                    [newParamsNotActiveYet]="!currentManager.editMode && newParamsNotActiveYet()"
                    (addWatchUsersClicked)="manageWatchUsers()">
  </app-tenders-list>
</div>
<router-outlet></router-outlet>

<!--toast for all possible action from user on marketWatch entity : edit|rename|save|delete-->
<ng-template #marketWatchActionToast let-toast="toast" let-toastService="toastService">
  <div class="position-relative d-flex w-100 h-100">
    <div class="icon icon--close position-absolute align-self-center" (click)="toastService.remove(toast)"></div>
    <!--icon displayed when success only-->
    <div class="d-flex flex-column align-items-start justify-content-start">
      <i *ngIf="!toast['classname'].includes('error')"
         class="ph ph-check-circle align-self-center"></i>
    </div>
    <div class="fw-bold align-self-center toast-text pdr-16px pdl-16px">
      <!--error message-->
      <ng-container *ngIf="toast['classname'].includes('error'); else success">
        <p class="m-0">{{'tenders.market-watches.toast.'+toast['action']+'-error' | translate}}</p>
      </ng-container>
      <!--success message-->
      <ng-template #success>
        <p class="m-0">{{'tenders.market-watches.toast.'+toast['action']+'-success' | translate:({name: toast['name']})}}</p>
        <p class="m-0 fw-light" *ngIf="toast['action']==='save'">{{'tenders.market-watches.toast.save-subtitle' | translate }}</p>
        <p class="m-0 fw-light" *ngIf="toast['action']==='edit'">{{'tenders.market-watches.toast.edit-subtitle' | translate }}</p>
      </ng-template>
    </div>
  </div>
</ng-template>
