import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ToastMessageService } from '../../shared/services/toast-message.service';

@Component({
  selector: 'app-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss']
})
export class ToastMessageComponent implements OnInit, OnDestroy {
  @Input() createMessage!: string;
  @Input() editMessage!: string;
  @Input() duplicationMessage?: string;
  public showToast = false;
  public name?: string;
  public editMode!: boolean;
  public duplication ? = false;

  constructor(private toastMessageService: ToastMessageService) { }

  ngOnInit(): void {
    this.toastMessageService.getSettingFinished()
      .subscribe((data) => {
        this.showToast = data.finished;
        this.name = data.name;
        this.editMode = !!data.id;
        this.duplication = data?.duplication;
      });
  }

  closeToast() {
    this.showToast = false;
  }

  ngOnDestroy(): void {
    this.toastMessageService.setFinished(false);
  }
}
