import { Component, Input, OnInit } from '@angular/core';

const colorMap = {
    blue: {backgroundColor: 'var(--info-transparent)', color: 'var(--info)'},
    red: {backgroundColor: 'var(--danger-transparent)', color: 'var(--danger)'},
    gray: {backgroundColor: 'var(--basic-250)', color: 'var(--basic-600)'},
}

@Component({
  selector: 'app-remaining-days-badge',
  templateUrl: './remaining-days-badge.component.html',
  styleUrls: ['./remaining-days-badge.component.scss']
})
export class RemainingDaysBadgeComponent implements OnInit{
  @Input({required: true}) days!: number
  @Input() color: 'blue' | 'red' | 'gray' = 'gray'

  ngOnInit() {
    if (this.days < 0) {
      this.color = 'gray'
    } else
    if (this.days <= 7) {
      this.color = 'red'
    }
  }

  protected readonly colorMap = colorMap;
}
