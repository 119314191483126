import { AfterViewChecked, Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: '[overflowTooltip]'
})
export class OverflowTooltipDirective implements AfterViewChecked{
  @Input() watchTarget?: HTMLElement;
  @Input() overflowThreshold = 0;

  constructor(private elementRef: ElementRef, private renderer: Renderer2, private tooltip: NgbTooltip) {
  }

  ngAfterViewChecked() {
    this.checkOverflow();
  }

  checkOverflow() {
    const nativeElement = this.watchTarget ?? this.elementRef.nativeElement;

    this.renderer.setProperty(this.tooltip, 'disableTooltip',
      !(nativeElement.scrollHeight - this.overflowThreshold > nativeElement.offsetHeight ||
        nativeElement.scrollWidth - this.overflowThreshold > nativeElement.offsetWidth)
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkOverflow();
  }
}
