import { Component, OnInit } from '@angular/core';
import { TenderEntityService } from '../../services/tender-entity.service';
import { Period, TendersSearchService } from '../../services/tenders-search.service';
import { ApiTenderService } from '../../services/api/api-tender.service';
import { FilterType } from '../tenders-filter/tenders-filter.component';
import { DbPeriod } from '../filter-date/filter-date.component';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { TendersModuleService } from '../../services/tenders-module.service';
import { DisplayItem } from '../../../common-explain/components/ex-dropdown/ex-dropdown.component';
import {
  ApiSearchServiceEnum,
  DbPostSearchSort,
  PostTenderSearchBody,
  SortFieldEnum
} from '../../models/tender-search-body';
import { SortDirEnum } from '../../../common-explain/models/sort';
import { DbCountStatusItem, StatusItem } from '../../models/tender-status';
import { debounceTime, filter, throttleTime } from "rxjs/operators";
import {AutoUnsubscribe} from "../../../common-explain/decorators/auto-unsubscribe";

const COUNT_DEFAULT_SORT: DbPostSearchSort = {dir: SortDirEnum.ASC, field: SortFieldEnum.CLOSING_DATE}

@Component({
  selector: 'app-tenders-board',
  templateUrl: './tenders-board.component.html',
  styleUrls: ['./tenders-board.component.scss']
})
@AutoUnsubscribe
export class TendersBoardComponent implements OnInit {
  protected readonly FilterType = FilterType;

  tendersSearchService: TendersSearchService

  defaultPeriod: DbPeriod;
  showFilters = false;
  status: DisplayItem<StatusItem>[] = [];
  statusCounts: DbCountStatusItem[] = [];


  constructor(
    protected tenderEntityService: TenderEntityService,
    private apiTenderService: ApiTenderService,
    private tenderModuleService: TendersModuleService
  ) {
    this.tendersSearchService = new TendersSearchService(apiTenderService);
    this.tendersSearchService.sort = {field: SortFieldEnum.CLOSING_DATE, dir: SortDirEnum.ASC};
    let from = new Date();
    from.setMonth(from.getMonth() - 3);
    this.defaultPeriod = {
      from: new NgbDate( from.getFullYear(), from.getMonth() + 1, from.getDate() ),
      to: new NgbDate( new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate() )
    }
  }

  async ngOnInit() {
    await this.tenderModuleService.groupAccountStatuses.then(() => {
      this.status = this.tenderModuleService.statusItems;
    });
    await this.tenderModuleService.groupAccountStatuses.then((res) => {
      this.tendersSearchService.selectedStatusIds = res.data.map((status) => status.id);
    })

    this.tendersSearchService._filters$
      .pipe(
        filter((filters) => filters?.propagate ?? true),
        debounceTime(200),
        throttleTime(2000, undefined, {leading: true, trailing: true})
      )
      .subscribe(filters => {
        if (
          this.tendersSearchService.filters?.territories?.length && (this.tendersSearchService.filters?.topics?.length || this.tendersSearchService.filters?.text?.length || this.tendersSearchService.filters?.status_ids?.length) &&
          this.tendersSearchService.filters.indexation_period?.from && this.tendersSearchService.filters.indexation_period.to && this.tendersSearchService.filters.tender_types?.length
        ) {
          const countByStatusBody = new PostTenderSearchBody(
            this.tendersSearchService.filters.topics,
            this.tendersSearchService.filters.text,
            this.tendersSearchService.filters.territories,
            this.tendersSearchService.filters.indexation_period,
            this.tendersSearchService.filters.tender_types,
            undefined,
            this.tendersSearchService.filters.offset ?? 0,
            this.tendersSearchService.filters.limit ?? 25,
            this.tendersSearchService.filters.sort ?? COUNT_DEFAULT_SORT
          )
          countByStatusBody.service = ApiSearchServiceEnum.COUNT_QUALIFIED_TENDERS;
          this.apiTenderService.search.retrieveCountByStatus(countByStatusBody)
            .then((res) => {
              this.statusCounts = res;
            })
        }
      })
  }

  periodSelected(event: Period) {
    this.tendersSearchService.selectedPeriod = event;
  }

  protected readonly SortFieldEnum = SortFieldEnum;
}
