import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {HeadersService} from "../../../../shared/services/api/headers/headers.service";
import { Observable, timeout } from "rxjs";
import {EventTypeName} from "../../../../models/user-tracker";
import {environment} from "../../../../../environments/environment";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ApiDceService {

  errorMessage = 'L\'url de téléchargement du DCE est undefined';

  constructor(private http: HttpClient,
              private headerService: HeadersService) {
  }

  getDceDownloadUrl(projectId: string, dceProjectUid: string, inquirers: string[], givenTimeout: number): Observable<string> {
    return this.http.post<{ s3_download_doc_url: string, errorMessage?: string }>(
      `${environment.explainApiGatewayUrl}export-document`,
      {
        project_uid: projectId,
        dce_project_uid: dceProjectUid,
        inquirers: inquirers,
        entity_id: 'tender',
      },
      this.headerService.getTrackedHttpHeaders(EventTypeName.DCE_DOWNLOAD_TRIGGERED)
    )
      .pipe(
        timeout(givenTimeout),
        map(res => {
          if (res.errorMessage) { // j'ai eu un cas 200 avec un errorMessage dans la réponse...
            throw new Error(res.errorMessage);
          }
          return res.s3_download_doc_url
        })
      );
  }

  getBlobFromUrl(url: string | undefined): Observable<Blob> {
    if (url === undefined) throw new Error(this.errorMessage);
    return this.http.get(
      url ?? '',
      {
        headers: this.headerService.getTrackedHttpHeaders(EventTypeName.DCE_DOWNLOADED).headers,
        responseType: 'blob'
      }
    ).pipe(
      map((blob) => {
        return blob;
      })
    )
  }
}
