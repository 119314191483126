<div [hidden]="tenderEntityService.tenderId$ | async" id="mb-container">
  <div class="px-4 py-2">
    <div class="mb-title">
      {{'tenders.board.title' | translate}}
    </div>
    <div class="d-flex flex-column gap-2">
      <div class="filters">
        <app-filter-status [tendersSearchService]="tendersSearchService"
                           [items]="status"
                           [statusCounts]="statusCounts"/>
        <div class="vr my-1"></div>
        <div [hidden]="!showFilters">
          <app-tenders-filter
            [tenderSearchService]="tendersSearchService"
            [filters]="[FilterType.TERRITORY, FilterType.TYPE]"
            noLabel
          />
        </div>
        <div class="toggle-filters" (click)="showFilters = !showFilters">
          {{(showFilters ? 'tenders.board.less-filters' : 'tenders.board.more-filters') | translate}}
        </div>
      </div>
      <div class="d-flex gap-3 align-items-center">
        <app-filter-date (periodSelected)="periodSelected($event)" [defaultPeriod]="defaultPeriod"/>
        <app-tenders-summary [tendersSearchService]="tendersSearchService"
                             noPeriodDisplayed
                             class="flex-grow-1"/>
        <span *ngIf="false" class="text-basic">Rechercher</span>
        <app-tenders-sort [tendersSearchService]="tendersSearchService"
                          [sortFields]="[SortFieldEnum.INDEXATION_DATE, SortFieldEnum.CLOSING_DATE]"
                          defaultSort="closing_date"
                          inBoardView/>
      </div>
    </div>
  </div>
  <app-tenders-list [tendersSearchService]="tendersSearchService" inBoardView/>
</div>
<router-outlet></router-outlet>
