import { Injectable } from '@angular/core';
import { HttpHeadersService } from '../http-headers/http-headers.service';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { DbStatusItem } from '../../../models/tender-status';

export interface AnnotationStatus {
  id?: number;
  statusName?: string;
  displayedStatusName?: string;
}

export interface DbTenderAnnotation {
  group_account_id?: number,
  id: number,
  note?: string,
  status?: DbStatusItem
  tender_uid: string,
  created_at?: string,
  updated_at?: string
}

@Injectable({
  providedIn: 'root'
})
export class ApiAnnotationService {
  constructor(private httpHeadersService: HttpHeadersService,
              private http: HttpClient) {
  }

  addAnnotation(body: {tender_uid: string, status_id?: number, note?: string}) {
    return firstValueFrom(this.http
      .put<{
        id: number,
        message: string
      }>(
        `${environment.explainApiUrl}tender_annotations`,
        JSON.stringify(body),
        this.httpHeadersService.httpHeaders
      )
    )
  }

  updateAnnotation(body: {status_id?: number | null, note?: string, id: number}): Promise<any> {
    return firstValueFrom(this.http
      .patch(
        `${environment.explainApiUrl}tender_annotations`,
        JSON.stringify(body),
        this.httpHeadersService.httpHeaders
      )
    )
  }

  getAnnotation(tenderUid: string): Promise<DbTenderAnnotation> {
    return firstValueFrom(this.http
      .get<DbTenderAnnotation>(
        `${environment.explainApiUrl}tender_annotations?tender_uid=${tenderUid}`,
        this.httpHeadersService.httpHeaders,
      )
    );
  }

  getNote(tenderUid: string): Promise<{note:string, annotationId: number}> {
    return firstValueFrom(this.http
      .get<DbTenderAnnotation>(
        `${environment.explainApiUrl}tender_annotations?tender_uid=${tenderUid}`,
        this.httpHeadersService.httpHeaders,
      ).pipe(map((res: DbTenderAnnotation) => {
          return {note: res.note ?? '', annotationId: res.id ?? 0}
        })
      ));
  }

  createNote(id: string, note: string) {
    return this.addAnnotation({tender_uid: id, note});
  }

  updateNote(annotationId: number, note: string): Promise<string> {
    return this.updateAnnotation({note: note, id: annotationId});
  }

  retrieveGroupAccountStatuses(body: any): Promise<{data: Array<{ id: number, status_name: string, displayed_status_name: string }> }> {
    return firstValueFrom(this.http
      .post<{data: Array<{ id: number, status_name: string, displayed_status_name: string }> }>(
        `${environment.explainApiUrl}group_account_statuses`,
        JSON.stringify(body),
        this.httpHeadersService.httpHeaders
      )
    );
  }
}
