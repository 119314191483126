import { booleanAttribute, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ex-note-editor',
  templateUrl: './ex-note-editor.component.html',
  styleUrls: ['./ex-note-editor.component.scss']
})
export class ExNoteEditorComponent implements OnChanges {

  @Input({required: true}) service!: NoteService<any>;
  @Input({required: true}) entityId!: string;
  @Input() dbNote: string = '';
  @Input() shouldGetUpdatedValueOnSelection?: boolean;
  @Input({transform: booleanAttribute}) displayText = false;
  @Input() context: 'in-list-view' | 'in-detail-view' = 'in-list-view';

  note: string = '';
  @Input() annotationId: number = 0;
  isPanelOpen = false;
  mode: 'edition' | 'view' = 'view';
  forceToggle$ = new EventEmitter<boolean>();

  @Output() modeChange = new EventEmitter<'edition' | 'view'>()
  @Output() noteChange = new EventEmitter<string>()
  @Output() isEditorOpen = new EventEmitter<{id: string, isOpen: boolean}>()

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dbNote']) {
      this.note = this.dbNote;
    }
  }

  async getNote() {
    if (!this.shouldGetUpdatedValueOnSelection) return;
    const {note, annotationId} = await this.service.getNote(this.entityId);
    this.note = note;
    this.annotationId = annotationId;
  }

  async updateNote() {
    if (this.annotationId) await this.service.updateNote(this.annotationId, this.note);
    if (!this.annotationId) await this.service.createNote(this.entityId, this.note);
    this.toggleMode();
    this.forceToggle$.emit(false);
    this.noteChange.emit(this.note);
    this.dbNote = this.note;
  }

  toggleMode() {
    if (this.mode === 'edition') {
      this.mode = 'view';
    } else {
      this.mode = 'edition';
    }
    this.modeChange.emit(this.mode);
  }

  clickOutside(event: boolean) {
    if (event && this.mode === 'edition') {
      this.updateNote();
    }
  }

  onDropdownToggle(event: boolean) {
    if (event) this.getNote().then(); else this.noteChange.emit(this.note);
    this.isEditorOpen.emit({id: this.entityId, isOpen: event});
    this.isPanelOpen = event;
  }
}

export interface NoteService<T> {
  getNote(id: string): Promise<T>;
  createNote(id: string, note: string): Promise<T>;
  updateNote(id: number, note: string): Promise<T>;
  deleteNote?(id: string): Promise<void>;
}
