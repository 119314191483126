<div class="modal-header">
  <h4 class="modal-title">{{ 'tenders.market-watches.modal-share-watch.title' | translate }}</h4>
  <i class="ph-bold ph-x"
            (click)="cancel()"></i>
</div>
<div class="modal-body">
  <div class="checkbox">
    <label class="container d-flex">
      <div [ngClass]="{'disabled': !note}"
           [ngbTooltip]="!note ? tenderWithoutNote : ''"
           tooltipClass="tooltip-share-watch"
           placement="bottom">

      <input type="checkbox" class="form-check-input"
               (change)="toggleCheckbox()"
               [checked]="displayNote && note"
               [disabled]="!note"
        >
        <span class="checkmark"></span>
        <span class="checkbox-label">{{ 'tenders.market-watches.modal-share-watch.display-note' | translate }}</span>
  </div>
    </label>

  </div>

  <div *ngIf="showLoader" class="loader-container">
    <app-simple-loader [vertical]="true"></app-simple-loader>
  </div>
  <div *ngIf="!showLoader" id="contentToShare" class="metadata-box" contenteditable>
    <ng-container *ngTemplateOutlet="contentTemplate; context: {tender: tender}"/>
  </div>

</div>

<div class="modal-footer">
  <button type="button" class="btn confirm-copy d-flex" (click)="confirm()">
    <mat-icon class="copy-to-clipboard"
              svgIcon="grid-copy-filled"
              placement="bottom"></mat-icon>
    <span
      class="align-self-center">{{ 'common.copy-to-clipboard' | translate }}</span>
  </button>
</div>

<ng-template #contentTemplate let-tender="tender">
  <div class="title">{{removeEm(tender.title)}}</div>
  <div class="territories">{{ inquirers }} • {{ department }}</div>
  <br/>
  <div class="status">
    {{ 'tenders.market-watches.modal-share-watch.status' | translate }}
    {{ tender.status?.displayName ?? ('tenders.not-defined-m' | translate).toLowerCase() }}
  </div>
  <div class="published-on">
    {{ 'tenders.market-watches.modal-share-watch.avis' | translate }}
    {{ tender.tenderType ? ('tenders.badge.' + tender.tenderType | translate).toLowerCase() : ''}}{{ 'tenders.market-watches.modal-share-watch.published-on' | translate }}
    {{ tender.indexationDate?.toLocaleDateString() }}
  </div>
  <div *ngIf="tender.tenderType !== TenderTypesEnum.ATTRIBUTION" class="closing-date">
    {{ 'tenders.market-watches.modal-share-watch.closing-date' | translate }}
    {{ tender.closingDate ? tender.closingDate.toLocaleDateString() + ('tenders.market-watches.modal-share-watch.at-time' | translate) + ' '
    + tender.closingDate.toLocaleTimeString('fr-FR', {hour: '2-digit', minute: '2-digit', hour12: false})
    : ('tenders.not-detected-f' | translate).toLowerCase() }}
  </div>
  <div class="explain_link">
    {{ 'tenders.market-watches.modal-share-watch.explain-link' | translate }}{{' '}}
    <a contenteditable="false" href="{{route}}/{{tender.id}}">{{ route }}/{{ tender.id }}</a>
  </div>
  <br/>
  <div *ngIf="note && displayNote">
    <div class="note">
      {{ 'tenders.market-watches.modal-share-watch.note' | translate }}
    </div>
    {{ note }}
  </div>
</ng-template>

<ng-template #tenderWithoutNote>
  {{ 'tenders.market-watches.modal-share-watch.no-note-market' | translate }}
</ng-template>
