<div>
  <div class="chips d-flex">
    <ng-container *ngFor="let item of selectedItems">
      <ex-chip backgroundColor="color-mix(in srgb, {{item?.value?.color || 'transparent'}} 20%, transparent)"
               [color]="item.value.color ?? ''"
               (closeClick)="removeStatus(item.value.id)"
               class="text-basic">
        {{item.label}} ({{countOf(item)}})
      </ex-chip>
    </ng-container>

    <div *ngIf="unSelectedItems.length" class="chips-dropdown d-flex">
      <ex-dropdown noInput noChevron noBorder
        [items]="unSelectedItems"
        (change)="addStatus($event)">
        <ng-template *ngIf="selectedItems.length !== 0" exDropdownDisplay>
          <div class="chevron-btn">
            <i class="ph-bold ph-caret-down"></i>
          </div>
        </ng-template>
        <ng-template *ngIf="selectedItems.length === 0" exDropdownDisplay>
          <div class="marine-btn">
            <div class="marine-text">
              {{ 'Statut '}}
            </div>
            <i class="ph-bold ph-plus"></i>
          </div>
        </ng-template>
        <ng-template exDropdownItem
                     let-elm>
          <div class="item d-flex">
            <div class="color-square d-flex align-items-center justify-content-start">
              <svg width="16" height="16" fill="currentColor" [style.color]="elm.value.color">
                <path d="M12 14.5H4C2.89733 14.5 2 13.6027 2 12.5V4.5C2 3.39733 2.89733 2.5 4 2.5H12C13.1027 2.5 14 3.39733 14 4.5V12.5C14 13.6027 13.1027 14.5 12 14.5Z" fill="currentColor"/>
              </svg>
            </div>
            <div class="d-flex align-items-center justify-content-between w-100">
              <div>{{ elm.label }} ({{ countOf(elm) }})</div>
            </div>
          </div>
        </ng-template>
      </ex-dropdown>

    </div>
  </div>
</div>
