<div class="container">
    <div class="smartgrid-container">
        <div class="loader-container" *ngIf="showLoader">
            <app-simple-loader
                    [loaderText]="'tenders.smart-grid.grid-loader' | translate"
                    [vertical]="true">
            </app-simple-loader>
        </div>
        <div *ngIf="!showLoader && !tenderDceProjectUid" class="not-available">
            <div class="not-available-title">
                <span>{{'tenders.smart-grid.grid-not-available-title' | translate}}</span>
            </div>
            <div class="not-available-text">
                <span>{{'tenders.smart-grid.grid-not-available-no-dce' | translate}}</span>
            </div>
        </div>
        <div *ngIf="!showLoader && tenderDceProjectUid">
            <div *ngIf="!publicQuestions.length && !privateQuestions.length" class="not-available">
                <div class="not-available-title">
                    <span>{{'tenders.smart-grid.grid-not-available-title' | translate}}</span>
                </div>
                <div class="not-available-text">
                    <span>{{'tenders.smart-grid.grid-not-available-failed' | translate}}</span>
                </div>
            </div>
            <ng-container
                    *ngTemplateOutlet="questionCard;
        context: {questions: privateQuestions, type: GridQuestionTypeEnum.PRIVATE}">
            </ng-container>
            <ng-container
                    *ngTemplateOutlet="questionCard;
        context: {questions: publicQuestions, type: GridQuestionTypeEnum.PUBLIC}">
            </ng-container>
        </div>
    </div>
</div>
<ng-template #tooltipCopy>
    <span>{{'common.copy-to-clipboard' | translate}}</span>
</ng-template>
<ng-template #tooltipCopied>
    <span>{{'common.copied-to-clipboard' | translate}}</span>
</ng-template>
<ng-template #tooltipEditDelete>
    <span>{{'common.edit-delete' | translate}}</span>
</ng-template>

<ng-template #questionCard let-questions="questions" let-type="type">
    <div *ngIf="questions.length">
        <div class="header">
            <div class="header-title">
        <span
                *ngIf="type == GridQuestionTypeEnum.PUBLIC">{{ 'tenders.smart-grid.type.public' | translate | uppercase }}</span>
                <span
                        *ngIf="type == GridQuestionTypeEnum.PRIVATE">{{ 'tenders.smart-grid.type.private' | translate | uppercase }}</span>
            </div>
        </div>
        <div class="cards-container">
            <div *ngFor="let question of questions; trackBy: trackByQuestionId"
                 class="card-item"
                 [ngClass]="{
                    'active': clickedAnswer && isEqual(clickedAnswer, question.answer),
                    'disabled': !question.answer && type === GridQuestionTypeEnum.PUBLIC,
                    'disabled-private': !question.answer && type === GridQuestionTypeEnum.PRIVATE
                 }"
                 (click)="onClickQuestion(question)"
            >
                <div class="question-card-content">
                    <div class="question-card-header">
                        <div [ngClass]="{'not-available-answer': question.isGenerationFinished && !question.answer}"
                             class="question-card-title">
                            {{question.displayedName}}
                            <img
                                    *ngIf="type == GridQuestionTypeEnum.PRIVATE"
                                    [ngbTooltip]="tooltipEditDelete"
                                    tooltipClass="custom-tooltip-smart-grid-cards"
                                    placement="top"
                                    class="icon-edit"
                                    src="/assets/images/icon-pencil-filled.svg" alt="arrow-circle" height="16"
                                    width="16"
                                    (click)="editQuestion(question)">
                            <div *ngIf="!question.answer && question.isGenerationFinished "
                                 class="text not-available-answer-text">
                                ({{'tenders.smart-grid.card-not-available-failed' | translate}})
                            </div>
                        </div>
                        <img *ngIf="!isEqual(clickedAnswer, question.answer) && question.answer" class="arrow-circle"
                             src="/assets/images/27-icon-arrow-circle-right-fill-600.svg" alt="arrow-circle">
                        <div *ngIf="question.answer && isEqual(clickedAnswer, question.answer)"
                             class="card-selected-icons-container">
                            <mat-icon *ngIf="!isCopied" class="copy-to-clipboard"
                                      svgIcon="grid-copy-outline"
                                      (click)="copyToClipboard($event, question)"
                                      [ngbTooltip]="tooltipCopy"
                                      tooltipClass="custom-tooltip-smart-grid-cards"
                                      placement="bottom">
                            </mat-icon>
                            <mat-icon *ngIf="isCopied" class="copy-to-clipboard"
                                      svgIcon="grid-copy-filled"
                                      (click)="copyToClipboard($event, question)"
                                      [ngbTooltip]="tooltipCopied"
                                      tooltipClass="custom-tooltip-smart-grid-cards"
                                      placement="bottom">
                            </mat-icon>
                            <img class="close-circle" src="/assets/images/27-icon-close-circle-fill-info-500.svg"
                                 alt="close-circle">
                        </div>
                    </div>
                    <div *ngIf="!question.isGenerationFinished || question.answer" class="question-card-body">
                        <ngx-skeleton-loader *ngIf="!question.isGenerationFinished"/>
                        <div *ngIf="question.isGenerationFinished && question.answer" class="answer-container">
                            <div>
                                <div class="text"
                                     [ngClass]="{'cropped-answer': !isEqual(clickedAnswer, question.answer), 'full-answer': isEqual(clickedAnswer, question.answer) }">
                                    {{ question.answer.citation }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
