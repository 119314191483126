import { ChunkDetail, DbChunkDetail } from './chunk-detail';


export interface DbGridAnswer {
  citation: string;
  doc_uid: string;
  chunk_hash: string;
  chunk_detail: DbChunkDetail;
  previous_chunk_detail?: DbChunkDetail;
  following_chunk_detail?: DbChunkDetail;
}

export class GridAnswer {
  questionId: number;
  drawChunk: boolean;
  citation: string;
  docUid: string;
  chunkHash: string;
  chunkDetail: ChunkDetail;
  previousChunkDetail?: ChunkDetail;
  followingChunkDetail?: ChunkDetail;


  constructor(question_id: number, dbGridAnswer: DbGridAnswer) {
    this.questionId = question_id;
    this.drawChunk = question_id !== 21; // Exclude question : "Aperçu CCTP"
    this.citation = dbGridAnswer.citation;
    this.docUid = dbGridAnswer.doc_uid;
    this.chunkHash = dbGridAnswer.chunk_hash;
    this.chunkDetail = new ChunkDetail(dbGridAnswer.chunk_detail);
    this.previousChunkDetail = dbGridAnswer.previous_chunk_detail ? new ChunkDetail(dbGridAnswer.previous_chunk_detail) : undefined;
    this.followingChunkDetail = dbGridAnswer.following_chunk_detail ? new ChunkDetail(dbGridAnswer.following_chunk_detail) : undefined;
  }
}
