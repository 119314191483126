<div class="relevance-feedback">
  <ng-container *ngIf="tender.isRelevant === undefined && state && state === 'loading'">
    <mat-icon svgIcon="loader" class="icon-loader" />
  </ng-container>
  <ng-container *ngIf="tender.isRelevant === undefined && state && state !== 'loading'">
    <mat-icon svgIcon="check-circle-filled" class="icon-success" [hidden]="state === 'negative'" />
    <mat-icon svgIcon="x-circle-filled" class="icon-danger" [hidden]="state === 'positive'"/>
    <span>
      {{ 'tenders.relevance-feedback.submitted' | translate }}
    </span>
    <span class="text-decoration-underline cursor-pointer" (click)="cancelFeedback()">
      {{ 'tenders.relevance-feedback.cancel' | translate }}
    </span>
  </ng-container>
  <ng-container *ngIf="!state">
    <mat-icon svgIcon="sparks-filled" class="icon-ai"
              [ngbTooltip]="'tenders.badge.suggestion' | translate"
              placement="right auto"
              tooltipClass="tender-suggestion-tooltip"
              [openDelay]="500"
    />
    <ng-container *ngIf="tender.isRelevant === undefined">
      <span>{{ 'tenders.relevance-feedback.is-relevant' | translate }}</span>
      <mat-icon svgIcon="check-circle"
                class="icon-success clickable"
                (click)="submitFeedback(true)"
      />
      <mat-icon svgIcon="x-circle"
                class="icon-danger clickable"
                (click)="submitFeedback(false)"
      />
    </ng-container>
    <ng-container *ngIf="tender.isRelevant !== undefined">
      <span *ngIf="tender.isRelevant">{{ 'tenders.relevance-feedback.positive-feedback' | translate }}</span>
      <span *ngIf="!tender.isRelevant">{{ 'tenders.relevance-feedback.negative-feedback' | translate }}</span>
    </ng-container>

  </ng-container>
</div>
