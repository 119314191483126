import { DbDceDocumentUid, DceDocumentUid } from './dce-document-uid';

export interface DbDceDocument extends DbDceDocumentUid {
  name: string;
  preview_url: string;
  publication_type: TenderDceDocumentType | TenderAvisType;
  raw_key: string;
  is_smart_grid_doc: boolean;
}

export class DceDocument extends DceDocumentUid {
  name: string;
  previewUrl: string;
  publicationType: TenderDceDocumentType | TenderAvisType;
  rawKey: string;
  iconName?: DceDocumentIconName;
  isSmartGridDoc?: boolean;

  constructor(dbDceDocument: DbDceDocument) {
    super(dbDceDocument);
    this.name = dbDceDocument.name;
    this.previewUrl = dbDceDocument.preview_url;
    this.publicationType = dbDceDocument.publication_type;
    this.rawKey = dbDceDocument.raw_key;
    this.iconName = this.getIconName(dbDceDocument.name, dbDceDocument.publication_type);
    this.isSmartGridDoc = dbDceDocument.is_smart_grid_doc ?? false;
  }

  getIconName(name: string, publication_type: string): DceDocumentIconName {
    const nameParts = name.split('.');
    const extension = nameParts.length > 1 ? nameParts[nameParts.length - 1].toLowerCase() :
      Object.values(TenderAvisType).includes(publication_type as TenderAvisType) ? 'html' :
        'unknown';
    return fileIconMapping[extension] ?? DceDocumentIconName.FILE_UNKNOWN as DceDocumentIconName;
  }
}

export enum DceDocumentIconName {
  FILE_UNKNOWN = 'file-unknown',
  FILE_PDF = 'file-pdf',
  FILE_EXCEL = 'file-excel',
  FILE_PPT = 'file-ppt',
  FILE_TEXT = 'file-text',
  FILE_IMG = 'file-img',
  FILE_HTML = 'file-html'
}

interface FileExtensionMapping {
  [extension: string]: DceDocumentIconName;
}

export const fileIconMapping: FileExtensionMapping = {
  pdf: DceDocumentIconName.FILE_PDF,
  xlsx: DceDocumentIconName.FILE_EXCEL,
  xls: DceDocumentIconName.FILE_EXCEL,
  xml: DceDocumentIconName.FILE_PDF,
  ods: DceDocumentIconName.FILE_EXCEL,
  csv: DceDocumentIconName.FILE_EXCEL,
  docx: DceDocumentIconName.FILE_TEXT,
  doc: DceDocumentIconName.FILE_TEXT,
  odt: DceDocumentIconName.FILE_TEXT,
  rtf: DceDocumentIconName.FILE_TEXT,
  txt: DceDocumentIconName.FILE_TEXT,
  pptx: DceDocumentIconName.FILE_PPT,
  ppt: DceDocumentIconName.FILE_PPT,
  odp: DceDocumentIconName.FILE_PPT,
  dmp: DceDocumentIconName.FILE_IMG,
  jpg: DceDocumentIconName.FILE_IMG,
  jpeg: DceDocumentIconName.FILE_IMG,
  png: DceDocumentIconName.FILE_IMG,
  html: DceDocumentIconName.FILE_HTML,
  unknown: DceDocumentIconName.FILE_UNKNOWN
};

interface PublicationTypeToWeightMapping {
  [publicationType: string]: number;
}

export const aoDocumentImportanceMapping: PublicationTypeToWeightMapping = {
  ao_dce_reglement_consultation: 1,
  ao_dce_ccap: 2,
  ao_avis_initial: 3,
  ao_dce_cctp: 4,
  ao_dce_ae: 5,
  default_value: 7
}

export enum TenderDceDocumentType {
  AO_DCE = 'ao_dce',
  AO_DCE_REGLEMENT_CONSULTATION = 'ao_dce_reglement_consultation',
  AO_DCE_AUTRE = 'ao_dce_autre',
  AO_DOC_PRE_INFORMATION = 'ao_doc_pre_information'
}

export enum TenderAvisInitial {
  AO_AVIS_INITIAL = 'ao_avis_initial',
  AO_AVIS_SOURCE = 'ao_avis_source',
}

export enum TenderAvisRectificatif {
  AO_AVIS_RECTIFICATIF = 'ao_avis_rectificatif',
}

export enum TenderAvisResultatMarche {
  AO_AVIS_RESULTAT_MARCHE = 'ao_avis_resultat_marche',
  AO_AVIS_RM_ATTRIBUTION = 'ao_avis_rm_attribution',
  AO_AVIS_RM_ANNULATION = 'ao_avis_rm_annulation',
  AO_AVIS_RM_AUTRE = 'ao_avis_rm_autre',
}

export enum TenderAvisPreinformation {
  AO_AVIS_PRE_INFORMATION = 'ao_avis_pre_information',
}

// On ne peut pas appeler les autres Enum dans cette enum car ils créent leur propre scope.
// sonar cloud vraiment pas content si on fait AO_AVIS_INITIAL = TenderAvisInitial.AO_AVIS_INITIAL
export enum TenderAvisType {
  AO_AVIS_INITIAL = 'ao_avis_initial',
  AO_AVIS_SOURCE = 'ao_avis_source',
  AO_AVIS_RECTIFICATIF = 'ao_avis_rectificatif',
  AO_AVIS_RESULTAT_MARCHE = 'ao_avis_resultat_marche',
  AO_AVIS_RM_ATTRIBUTION = 'ao_avis_rm_attribution',
  AO_AVIS_RM_ANNULATION = 'ao_avis_rm_annulation',
  AO_AVIS_RM_AUTRE = 'ao_avis_rm_autre',
  AO_AVIS_PRE_INFORMATION = 'ao_avis_pre_information',
}

