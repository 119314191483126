<div class="day-swiper">
  <button class="day-swiper-button" (click)="prevDay()"
          [disabled]="isFirstWatchDay(date)">
    <i class="ph-bold ph-caret-left"></i>
  </button>

  <div *ngIf="!editMode && allowedDates.length > 0">
    <ex-dropdown class="no-select"
                 noChevron
                 noInput
                 size="small"
                 transparentStyle
                 largeBorder

    >


      <ng-template exDropdownDisplay>
        <ng-container *ngTemplateOutlet="currentDate"></ng-container>
      </ng-template>

      <ng-template exDropdownContent>
        <div class="date-picker-header">
          {{ 'tenders.market-watches.choose-existing-watch' | translate }}
        </div>

        <ngb-datepicker #datepicker
                        (dateSelect)="onDateSelection($event)"
                        [displayMonths]="1"
                        [minDate]="minNgbDate"
                        [maxDate]="maxNgbDate"
                        [startDate]="maxNgbDate"
                        [markDisabled]="isDayDisabled"
                        [dayTemplate]="dayTemplate"
                        outsideDays="collapsed">
        </ngb-datepicker>
      </ng-template>
    </ex-dropdown>
  </div>
  <div *ngIf="editMode || allowedDates.length === 0">
    <ng-container *ngTemplateOutlet="currentDate"></ng-container>
  </div>


  <button class="day-swiper-button" (click)="nextDay()" [disabled]="isLastWatchDay()">
    <i class="ph-bold ph-caret-right"></i>
  </button>
</div>


<ng-template #currentDate>
  <div class="day-swiper-label">
    <span class="ds-weekday">{{ getWeekDayString() }}</span>
    <span class="ds-day">{{ getDayString() }}</span>
    <span class="ds-month">{{ getMonthString() }}</span>
  </div>
</ng-template>

<ng-template #dayTemplate let-date let-focused="focused" let-disabled="disabled">
	<span class="round-custom-day"
    [class.focused]="isDayFocused(date)"
    [class.disabled]="disabled">
    {{ date.day }}
	</span>
</ng-template>
