<ex-dropdown
        class="no-select"
        icon="/assets/images/27-icon-calendar.svg"
        replaceChevron
        placeholder="Sélectionnez une date"
        noInput
        [inputText]="inputText"
        (change)="exDropdownChangeMethodTriggered($event)"
        (dropdownToggle)="dropdownToggled($event)"
        size="small"
>

    <ng-template exDropdownContent class="tender-filter-date">
        <ngb-datepicker #datepicker
                        (dateSelect)="onDateSelection($event)"
                        [displayMonths]="2"
                        [maxDate]="maxDate"
                        [dayTemplate]="dayTemplate"
                        [startDate]="startDate"
                        outsideDays="hidden">
        </ngb-datepicker>
    </ng-template>

</ex-dropdown>


<ng-template #dayTemplate let-date let-focused="focused" let-disabled="disabled">
	<span
            class="custom-day"
            [class.focused]="focused"
            [class.disabled]="disabled"
            [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)"
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null"
    >
		{{ date.day }}
	</span>
</ng-template>
