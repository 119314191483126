<div class="ex-badge"
     [style.height]="height+'px'"
     [style.width]="width"
     [style.padding]="padding/2+'px'+ ' '+ padding + 'px'"
     [style.background-color]="backgroundColor"
     [style.color]="color"
     [style.border-color]="(borderColor && isPercentage(borderColor) ?
          'color-mix(in srgb, currentColor '+ borderColor +', transparent)' : borderColor) || 'transparent'"
     [style.font-size]="height - padding * 2 + 'px'"
>
    <span *ngIf="icon"
          [style.height]="height - padding * 2 + 'px'"
          [style.width]="height - padding * 2 + 'px'"
    >
        <mat-icon [svgIcon]="icon"/>
    </span>
    <span [ngbTooltip]="tooltip" placement="auto" overflowTooltip>{{text}}</span>
</div>
