import {Injectable} from "@angular/core";
import {ChunkPageBbox} from "../../models/chunk-page-bbox";
import {NgxExtendedPdfViewerService} from "ngx-extended-pdf-viewer";


@Injectable({
  providedIn: 'root'
})

export class ChunkBoxManagerService {

  constructor(private ngxExtendedPdfViewerService: NgxExtendedPdfViewerService,) {
  }

  chooseChunkPosition(chunkPagesBboxes: ChunkPageBbox[], withScroll = false) {
    chunkPagesBboxes.sort((a, b) => a.pageNum - b.pageNum);
    if (chunkPagesBboxes.length === 2) {
      this.drawBoundingBox(chunkPagesBboxes[0], 'top');
      this.drawBoundingBox(chunkPagesBboxes[1], 'bottom', withScroll); // There is maximum 2 chunkPagesBboxes
    } else if (chunkPagesBboxes.length === 1) {
      this.drawBoundingBox(chunkPagesBboxes[0], 'both', withScroll);
    }
  }

  drawBoundingBox(chunkPagesBboxes: ChunkPageBbox, chunkPart: 'top' | 'bottom' | 'both' = 'both', withScroll = false) {
    const page = chunkPagesBboxes.pageNum + 1;
    const content = document.getElementById('viewer')?.querySelector(`[data-page-number="${page}"]`);
    const rect = document.createElement('div');

    rect.classList.add('chunk-box', `${chunkPart}-chunk`);
    if (chunkPart !== 'bottom') {
      rect.id = 'chunk-box';
    }

    /* Vertical positionning */
    const chunkCoordinates = this.calcCoordinates(chunkPagesBboxes, chunkPart);
    rect.style.top = chunkCoordinates[0] + '%';
    rect.style.height = chunkCoordinates[1] + '%';

    content?.appendChild(rect);
    if (withScroll) {
      this.ngxExtendedPdfViewerService.scrollPageIntoView(chunkCoordinates[2], {top: chunkCoordinates[3] + '%'});
    }
  }

  calcCoordinates(chunkPagesBboxes: ChunkPageBbox, chunkPart: 'top' | 'bottom' | 'both' = 'both') {
    const page = chunkPagesBboxes.pageNum + 1;
    const pdfsViewerHeight = document.getElementById('pdfsViewer')?.offsetHeight ?? 815; // valeur par défaut de 815px.

    const y0 = chunkPagesBboxes.bbox[1];
    const y1 = chunkPagesBboxes.bbox[3];
    const rectTopPercentage = 100 * (1 - Math.max(y1, y0)) - 7.5;       // Add a 7.5% margin to the top
    const rectHeightPercentage = 100 * Math.abs(y0 - y1) + 15;          // Add a 7.5% margin to the bottom
    const positiveScrollCorrection = 100 * (33 / pdfsViewerHeight);     // Correction due to top: -33px on pdf viewer container;

    const rectTopPercentageAdjusted = chunkPart === 'bottom' ? Math.min(0, rectTopPercentage) : Math.max(1, rectTopPercentage);
    const rectHeightPercentageAdjusted= chunkPart === 'top' ? Math.max(100, rectHeightPercentage) : Math.min(99 - rectTopPercentage, rectHeightPercentage);

    let scrollToCoordinate = chunkPart === 'both' ?
      rectTopPercentageAdjusted + (rectHeightPercentageAdjusted / 2) : 150;
    const scrollToPage = chunkPart === 'both' ? page : page - 1;
    scrollToCoordinate += positiveScrollCorrection;

    return [rectTopPercentageAdjusted, rectHeightPercentageAdjusted, scrollToPage, scrollToCoordinate];
  }

  removeBoundingBox() {
    const chunks = Array.from(document.getElementsByClassName('chunk-box'));
    for (const chunk of chunks) {
      chunk.remove();
    }
  }
}
