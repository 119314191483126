import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
/**
 * Class taking care of exporting PDF
 * Uses npm library FILE-SAVER, to save document onto user's computer.
 */
export class ExportService {

  /**
   * Method to save Blob as a PDF to the user's computer.
   */
  savePdfToComputer(adminDocPdfBlob: Blob, documentName: string): void {
    saveAs(adminDocPdfBlob, documentName);
  }

  saveZipToComputer(zipBlob: Blob | null, documentName: string): void {
    if (!zipBlob) return;
    saveAs(zipBlob, documentName);
  }
  
  getFileNameFromURL(url: string): string | null {
    const urlObject = new URL(url);
    const pathParts = urlObject.pathname.split('/');
    const fileName = pathParts[pathParts.length - 1];
    return fileName;
  }
}
