import { isEqual } from 'lodash';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiTenderService } from '../../services/api/api-tender.service';
import { firstValueFrom, interval, takeWhile } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TenderDetail } from '../../models/tender-detail';
import { GridQuestion, GridQuestionFormActionEnum, GridQuestionTypeEnum } from '../../models/grid-question';
import { GridAnswer } from '../../models/grid-answer';
import { TenderEntityService } from '../../services/tender-entity.service';
import { UserTrackerService } from '../../../shared/services/tracking/user-tracker.service';
import { EventTypeName } from '../../../models/user-tracker';
import { AutoUnsubscribe } from '../../../common-explain/decorators/auto-unsubscribe';
import { DbTenderAnnotation } from "../../services/api/status/api-annotation.service";
import { Tender } from "../../models/tender";

@Component({
    selector: 'app-tender-detail',
    templateUrl: './tender-detail.component.html',
    styleUrls: ['./tender-detail.component.scss']
})
@AutoUnsubscribe
export class TenderDetailComponent implements OnInit, OnDestroy {
    protected readonly GridQuestionTypeEnum = GridQuestionTypeEnum;
    protected readonly JSON = JSON;

    tenderId!: string;
    tenderDetail!: TenderDetail;
    dbTenderAnnotation!: DbTenderAnnotation;
    grid: { [key in GridQuestionTypeEnum]: { questions: GridQuestion[], ready: boolean } } = {
        [GridQuestionTypeEnum.PUBLIC]: {questions: [], ready: false},
        [GridQuestionTypeEnum.PRIVATE]: {questions: [], ready: false}
    };
    clickedAnswer: GridAnswer | null = null;
    numberApiCalls = 0;
    maxApiCalls = 25;
    apiCallsInterval = 2000;
    @ViewChild('iframeNewQuestion') iframeNewQuestion?: ElementRef;
    isQuestionFormOpened = false;
    questionFormAction: GridQuestionFormActionEnum = GridQuestionFormActionEnum.ADD;
    questionToEdit: GridQuestion | null = null;
    mode: 'edition' | 'view' = 'view';

    constructor(private route: ActivatedRoute,
                protected apiTenderService: ApiTenderService,
                private tenderEntityService: TenderEntityService,
                private userTrackerService: UserTrackerService) {
    }

    async ngOnInit() {
        const route = this.route.snapshot;
        this.tenderId = route.params['tenderId'];
        this.tenderEntityService.tenderId$.next(this.tenderId);
        const dbTenderDetail = await this.apiTenderService.tenderDetail
          .getTenderDetail(this.tenderId, route.queryParams['from'])
          .catch(() => {
              return {} as any;
          });
        this.dbTenderAnnotation = await this.apiTenderService.annotation.getAnnotation(this.tenderId)
          .catch(() => {return {} as any;});
        if (this.dbTenderAnnotation.status) dbTenderDetail.status = this.dbTenderAnnotation.status;
        if (this.dbTenderAnnotation.note) dbTenderDetail.note = this.dbTenderAnnotation.note;
        this.tenderDetail = new TenderDetail(dbTenderDetail);

        if (this.tenderDetail.dceProjectUid) {
            await Promise.all([
                this.fetchQuestions(GridQuestionTypeEnum.PUBLIC),
                this.fetchQuestions(GridQuestionTypeEnum.PRIVATE)
            ]);
            await Promise.all([
                this.fetchAnswers(GridQuestionTypeEnum.PUBLIC, this.grid[GridQuestionTypeEnum.PUBLIC].questions),
                this.fetchAnswers(GridQuestionTypeEnum.PRIVATE, this.grid[GridQuestionTypeEnum.PRIVATE].questions)
            ]);
        }
    }

    async fetchQuestions(type: GridQuestionTypeEnum) {
        let questions: GridQuestion[] = [];
        try {
            questions = await firstValueFrom(this.apiTenderService.gridQuestion.getTenderGridQuestions(this.tenderDetail.dceProjectUid, type));
            if (type == GridQuestionTypeEnum.PRIVATE) {
                questions.sort((a, b) => a.displayedName.localeCompare(b.displayedName));
            }
            this.grid[type].questions = questions;
        } catch (e) {
            console.error('Error while fetching grid questions', e);
        }
        this.grid[type].ready = true;
    }

    async fetchAnswers(type: GridQuestionTypeEnum, gridQuestions: GridQuestion[]) {
        const initTime = Date.now();
        if (gridQuestions.length) {
            interval(this.apiCallsInterval).pipe(
              takeWhile(() => gridQuestions.map((question) => question.isGenerationFinished).includes(false)
                && this.numberApiCalls < this.maxApiCalls),
              finalize(() => {
                  gridQuestions.forEach(q => q.isGenerationFinished = true);
                  const generationTime = Date.now() - initTime;
                  this.trackEvent(
                    {generation_time_in_ms: generationTime},
                    EventTypeName.TENDER_GRID_GENERATION_DURATION);
              })
            ).subscribe(async _ => {
                const res = await firstValueFrom(this.apiTenderService.gridQuestion.getTenderGridAnswers(this.tenderDetail.dceProjectUid, type))
                  .catch(console.error);
                if (res && !isEqual(gridQuestions, res)) {
                    if (type == GridQuestionTypeEnum.PRIVATE) {
                        res.sort((a, b) => a.displayedName.localeCompare(b.displayedName));
                    }
                    this.grid[type].questions = res;
                }
                this.numberApiCalls++;
            });
        }
        this.numberApiCalls = 0;
    }

    openAddPrivateQuestionForm() {
        this.isQuestionFormOpened = true;
        this.questionFormAction = GridQuestionFormActionEnum.ADD;
    }

    openEditPrivateQuestionForm(question: GridQuestion) {
        this.isQuestionFormOpened = true;
        this.questionFormAction = GridQuestionFormActionEnum.EDIT;
        this.questionToEdit = question;
    }

    async handleQuestionAdded() {
        this.isQuestionFormOpened = false;
        await this.fetchQuestions(GridQuestionTypeEnum.PRIVATE);
        await this.fetchAnswers(GridQuestionTypeEnum.PRIVATE, this.grid[GridQuestionTypeEnum.PRIVATE].questions);
    }

    ngOnDestroy() {
        this.tenderEntityService.tenderId$.next('');
    }

    trackEvent(data = {}, eventName = '') {
        firstValueFrom(this.userTrackerService.track({
            event_type: eventName,
            event_timestamp: (new Date()).toISOString(),
            ...this.userTrackerService.buildBasicUserInformations(),
            tender_id: this.tenderDetail.id,
            ...data
        }));
    }

    onScrollEnd() {
        this.trackEvent({is_question_selected: this.clickedAnswer !== null, question: this.clickedAnswer},
          EventTypeName.TENDER_GRID_SCROLLED);
    }

    noteChange(tender: Tender, note: string) {
        tender.note = note;
        this.reloadTenderNoteEditorComponent(tender);
    }

    reloadTenderNoteEditorComponent(tender: Tender) {
        tender.hide = true;
        setTimeout(() => {
            tender.hide = false;
        });
    }
}
